<template>
  <div>
    <b-modal :id="id_modal" :title="'Histórico Médio ' + row.item.nu_name" size="xl">
      <b-row>
        
        <b-col>
          <b-table :items="items" :fields="fields" sortBy="entry_date" :sort-desc=sortDesc>
            <template #cell(valvar)="data">
              <variation
                  :key="data.item.key"
                  :valor="data.item.valvar"
                ></variation>
            </template>

            <template #cell(totalvar)="data">
              <variation
                  :key="data.item.key"
                  :valor="data.item.totalvar"
                ></variation>
            </template>

             <template #cell(total_perc_var)="data">
              <variation
                  :key="data.item.key"
                  :valor="data.item.total_perc_var"
                ></variation>
            </template>

          </b-table>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100"></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
 const vcr = require("./../helpers/varculator");

export default {
  props: {
    row: {
      type: Object,
      default: null,
    },
    id_modal: String,
  },
  data() {
    return {
      filter:'',
      items: [],
      sortDesc: true,
      fields: [
        {
          key: "entry_date",
          label: "Data",
          formatter: (value) => {
            return this.formataData(value);
          },
          sortable: true,
        },
        {
          key: "nu_invested",
          label: "Aplicado",
          sortable: true,
        },
        {
          key: "nu_amount",
          label: "Cotas",
          sortable: true,
        },
        {
          key: "price",
          label: "Cotação",
          sortable: true,
        },
        {
          key: "nu_balance",
          sortable: true,
          label: "Saldo",
        },
        {
          key: "valvar",
          sortable: true,
          label: "Últ. Var",
        },
        {
          key: "totalvar",
          sortable: true,
          label: "Total Var",
        },
        {
          key: "total_perc_var",
          sortable: true,
          label: "% Total Var",
        },
        
      ],
    };
  },
  mounted() {
    if (this.row != null) {
      this.getHistory();
    }
  },
  methods: {
    async getHistory() {
      this.axios
        .get(`/api/history-avg-var/${this.row.item.nu_name}`)
        .then((response) => {
          this.items = response.data;

          this.items.sort((a, b) => {
            return a.entry_date > b.entry_date ? 1 : -1;
          });

          for (let x = this.items.length - 1; x > 0; x--) {

            this.$set(
              this.items[x],
              "valvar",
              (
                parseFloat(this.items[x].nu_balance) -
                parseFloat(this.items[x - 1].nu_balance)
              ).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
              })
            );

            this.$set(
              this.items[x],
              "totalvar",
              (
                parseFloat(this.items[x].nu_balance) -
                parseFloat(this.items[x].nu_invested)
              ).toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
              })
            );

            this.$set(
              this.items[x],
              "total_perc_var",
              (
                 vcr
                .calcPercVarString(this.items[x].totalvar, this.items[x].nu_invested)
                
              )
            );

          }
        });
    },
    formataData(dt) {
      const dth = require("../helpers/data_handler");
      return dth.yyyymmddToddmmyyyy(dt);
    },
  },
};
</script>
