<template>
  <div>
    <b-modal :id="id_modal" title="Histórico" size="xl">
      <b-row>
        <b-col>
          <b-table :items="items" :fields="fields"
          sortBy="entry_date"
              :sort-desc="sortDesc">
            <template #cell(valvar)="data">
              <span
                v-bind:class="{
                  'text-success': parseFloat(data.item.valvar) > 0,
                  'text-danger': parseFloat(data.item.valvar) < 0,
                }"
              >
                {{ data.item.valvar }}</span
              >
            </template>
          </b-table>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100"></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: null,
    },
    id_modal: String,
  },
  data() {
    return {
      items: [],
      sortDesc: true,
      fields: [
        {
          key: "entry_date",
          label: "Data",
          sortable: true,
          formatter: (value) => {
            return this.formataData(value);
          },
        },
        {
          key: "balance",
          sortable: true,
          label: "Valor",
        },
        {
          key: "vl_contribution",
          sortable: true,
          label: "Aporte",
        },
        {
          key: "valvar",
          sortable: true,
          label: "Variação",
        },
      ],
    };
  },
  mounted() {
    if (this.row != null) {
      this.getHistory();
    }
  },
  methods: {
    async getHistory() {
      const vcr = require("./../helpers/varculator");

      this.axios.get(`/api/history/${this.row.item.id}`).then((response) => {
        this.items = response.data;

        this.items.sort((a, b) => {
          return a.entry_date > b.entry_date ? 1 : -1;
        });

        for (let x = this.items.length - 1; x > 0; x--) {
          let vl_contribution = 0;

          if (this.items[x].vl_contribution != null) {
            vl_contribution = vcr.localeNumToFloat(
              this.items[x].vl_contribution
            );
          }

          this.$set(
            this.items[x],
            "valvar",
            (
              parseFloat(this.items[x].balance) -
              vl_contribution -
              parseFloat(this.items[x - 1].balance)
            ).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
            })
          );
        }

        // this.$set(
        //   el,
        //   "perc_var",
        //   vcr
        //     .calcPercVar(el.valvar, el.nu_invested)
        //     .toLocaleString("pt-BR", {
        //       minimumFractionDigits: 2,
        //     })
        // );
      });
    },
    formataData(dt) {
      const dth = require("./../helpers/data_handler");
      return dth.yyyymmddToddmmyyyy(dt);
    },
  },
};
</script>
