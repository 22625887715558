<template>
  <div>
    <b-modal id="modal_dividend" title="Nova Receita">
      <b-row>
        <b-col>
          <b-form @submit="onSubmit" @reset="onReset">
            <b-input-group prepend="" class="mb-2 mr-sm-2 mt-1">
              <b-form-input
                disabled
                id="inline-form-input-dt-appl"
                v-model="this.var_name"
                placeholder="ID investimento"
              ></b-form-input>
            </b-input-group>

            <b-input-group prepend="" class="mb-2 mr-sm-2 mt-1">
              <b-form-datepicker
                id="inline-form-input-dt-appl"
                placeholder="Data Registro"
                v-model="form.dt_entry"
              ></b-form-datepicker>
            </b-input-group>

            <b-input-group prepend="" class="mb-2 mr-sm-2 mt-1">
              <b-form-input
                id="inline-form-input-dt-appl"
                placeholder="Valor"
                v-model="form.vlr"
                autocomplete="off"
              ></b-form-input>
            </b-input-group>

            <b-input-group prepend="Tipo" class="mb-2 mr-sm-2 mt-1">
              <label
                class="sr-only"
                for="inline-form-custom-select-pref"
              ></label>
              <b-form-select
                id="inline-form-custom-select-pref"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="income_types"
                v-model="form.type"
                :value="null"
              ></b-form-select>
            </b-input-group>

            <b-button type="submit" variant="primary" class="me-2"
              >Submit</b-button
            >
            <b-button type="reset" variant="danger">Reset</b-button>

            <h2 v-show="show_msg_ok">{{ msg_ok }}</h2>
            <h2 v-show="show_msg_error">{{ msg_error }}</h2>
          </b-form>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100"></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        dt_entry: "",
        vlr: "",
        stock_name: "",
        id: this.var_name,
        type: null
      },
      msg_ok: "",
      msg_error: "",
      show_msg_ok: false,
      show_msg_error: false,
      income_types: null      
    };
  },
  mounted() {
    this.setIncomeTypes();
  },
  computed: {
    var_name() {
      return this.row.item.id;
    },
  },
  methods: {
    onSubmit(event) {
      this.show_msg_ok = false;
      this.show_msg_error = false;

      this.form.id_inv = this.var_name;

      event.preventDefault();
      this.axios.post("/api/entry-income", this.form).then((response) => {
        if (response.data.msg) {
          this.show_msg_ok = true;
          this.msg_ok = response.data.msg;
          this.onReset();
          return;
        }

        if (response.data.err) {
          this.msg_error = response.data.err;
          this.show_msg_error = true;
          return;
        }
      });
    },
    onReset() {
      this.form.dt_entry = "";
      this.form.vlr = "";
    },
    setIncomeTypes() {
      try {
        this.axios.get("/api/income-types").then((response) => {
          let x = response.data.map((el) => {
            return { value: el.id, text: el.no_name };
          });
          this.income_types = x;
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
