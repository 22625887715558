import { setupInterceptor, instance as axios } from "./helpers/api";

import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import ChartContainer from "./components/ChartsContainer.vue";
import EntryDividend from "./components/EntryDividend.vue";
import EntryExpense from "./components/EntryExpense.vue";
import EntryModal from "./components/EntryModal.vue";
import EntryVarModal from "./components/EntryVarModal.vue";
import GSignInButton from "vue-google-signin-button";

import HistoryModal from "./components/HistoryModal.vue";
import HistoryAvgQuoteModal from "./components/HistoryAvgQuoteModal.vue";
import HistoryQuoteModal from "./components/HistoryQuoteModal.vue";
import Home from "./views/Home.vue";
import MyInvestments from "./views/MyInvestments.vue";
import GroupedHistory from "./components/GroupedHistory.vue";
import SummaryContainer from "./components/SummaryContainer.vue";
import Variation from "./components/Variation.vue";

import routes from "./router/index";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";

import VueAxios from "vue-axios";
import VueLoading from "vue-loading-overlay";

import "vue-loading-overlay/dist/vue-loading.css";
import VueRouter from "vue-router";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "bootswatch/dist/cosmo/bootstrap.min.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

Vue.use(GSignInButton);

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueLoading);

Vue.use(VueRouter);

Vue.use(VueApexCharts);

setupInterceptor(Vue);

Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

Vue.component("apexchart", VueApexCharts);

Vue.component("chart-container", ChartContainer);

Vue.component("entry-dividend", EntryDividend);

Vue.component("entry-expense", EntryExpense);

Vue.component("entry-modal", EntryModal);

Vue.component("entry-var-modal", EntryVarModal);

Vue.component("history-modal", HistoryModal);

Vue.component("history-avg-quote-modal", HistoryAvgQuoteModal);

Vue.component("history-quote-modal", HistoryQuoteModal);

Vue.component("home", Home);

Vue.component("my-investments", MyInvestments);

Vue.component("grouped-history", GroupedHistory);

Vue.component("summary-container", SummaryContainer);

Vue.component("variation", Variation);

new Vue({
  router: routes,
  render: (h) => h(App),
}).$mount("#app");
