import axios from "axios";

export const setupInterceptor = (vm) => {
  let loader = null;

  function hideLoader() {
    loader && loader.hide();
    loader = null;
  }

  instance.interceptors.request.use(
    function (config) {
      //config.headers["G-Token"] = sessionStorage.id_token;

      if (loader) return config;

      loader = vm.$loading.show();
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  
  instance.interceptors.response.use(
    (response) => {
      hideLoader();
      return response;
    },
    (error) => {
      console.log(error);
      hideLoader();

      if(error.response.status == 401){
        window.location = "/login";
      }

      return Promise.reject(error);
    }
  );
};

export const instance = axios.create({
  //baseURL: 'http://localhost:8080',
  timeout: 10000,
  params: {}, // do not remove this, its added to add params later in the config
});



