<template>
  <div>
    <h3>Histórico Agrupado</h3>

    <b-row>
      <b-col class="mb-4">
        <b-input-group>
          <b-form-select
            id="inline-form-custom-select-pref"
            class="mb-2 mr-sm-2 mb-sm-0"
            :options="freqs_types"
            v-model="frequency"
            :value="null"
            @change="updateChartTotal()"
          ></b-form-select>
        </b-input-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-tabs content-class="mt-3">
          <b-tab title="Total" active>
            <b-col cols="12">
              <div id="chart">
                <apexchart
                  v-if="hasChartData"
                  type="line"
                  height="350"
                  :options="chartOptions"
                  :series="series"
                  :key="series.length"
                ></apexchart>
              </div>
            </b-col>
            <b-table
              sortBy="entry_date"
              :sort-desc="sortDesc"
              :items="totalActive"
              :per-page="perPageTotal"
              :current-page="currentPage"
              :fields="total_fields"
              class="evo-table"
              hover
            >
              <template #head(valvar)="data">
                <span class="text-info"> &#916;{{ data.label }}</span>
              </template>

              <template #cell(valvar)="data">
                <variation
                  :key="data.item.key"
                  :valor="data.item.valvar"
                ></variation>
              </template>

              <template #cell(var7d)="data" v-if="false">
                <variation
                  :key="data.item.key"
                  :valor="data.item.var7d"
                ></variation>
              </template>

              <template #cell(var28d)="data">
                <variation
                  :key="data.item.key"
                  :valor="data.item.var28d"
                ></variation>
              </template>

              <template #cell(var91d)="data">
                <variation
                  :key="data.item.key"
                  :valor="data.item.var91d"
                ></variation>
              </template>

              <template #head(perc_var)="data">
                <span class="text-info"> &#916;{{ data.label }}</span>
              </template>

              <template #cell(perc_var)="data">
                <variation
                  :key="data.item.key"
                  :valor="data.item.perc_var"
                ></variation>
              </template>

              <template #cell(totalvar)="data">
                <variation
                  :key="data.item.key"
                  :valor="data.item.totalvar"
                ></variation>
              </template>

              <template #cell(perc_total_var)="data">
                <variation
                  :key="data.item.key"
                  :valor="data.item.perc_total_var"
                ></variation>
              </template>
            </b-table>
            <b-pagination
              pills
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPageTotal"
              aria-controls="my-table"
            ></b-pagination>
          </b-tab>
        </b-tabs>
      </b-col>

      <b-col cols="12" class="mt-4">
        <b-row class="mb-4">
          <b-col cols="2">
            <b-form-group  label-cols="3" content-cols="4" :label="labelSelectTabs" label-for="selectSpecificRows">
              <b-form-select
                :options="optionsSpecificRows"
                v-model="specificRows"
                id="selectSpecificRows"
                @change="getTabHistory(activeTabSpecific)"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-tabs content-class="mt-3" fill activate-tab v-model="activeTabSpecific">
          <b-tab
            :title="i.no_name"
            v-for="i of items"
            :id="`ts_${i.nu_type}`"
            v-bind:key="i.nu_type"
            @click="getTabHistory(i.nu_type)"
          >
            <b-col cols="12">
              <div id="chart_specific">
                <apexchart
                  type="line"
                  height="350"
                  :options="specificChartOptions"
                  :series="specificSeries"
                ></apexchart>
              </div>
            </b-col>

            <b-table
              sortBy="entry_date"
              :sort-desc="sortDesc"
              :items="active_tabs_history"
              :per-page="perPageSpecific"
              :current-page="currentPageSpecific"
              :fields="exterior_fields"
              class="history-table"
              hover
            >
              <template #head(valvar)="data">
                <span class="text-info"> &#916;{{ data.label }}</span>
              </template>

              <template #cell(valvar)="data">
                <variation
                  :key="data.item.key"
                  :valor="data.item.valvar"
                ></variation>
              </template>

              <template #head(perc_var)="data">
                <span class="text-info"> &#916;{{ data.label }}</span>
              </template>

              <template #cell(perc_var)="data">
                <variation
                  :key="data.item.key"
                  :valor="data.item.perc_var"
                ></variation>
              </template>

              <template #cell(var7d)="data">
                <variation
                  :key="data.item.key"
                  :valor="data.item.var7d"
                ></variation>
              </template>

              <template #cell(var28d)="data">
                <variation
                  :key="data.item.key"
                  :valor="data.item.var28d"
                ></variation>
              </template>

              <template #cell(var91d)="data">
                <variation
                  :key="data.item.key"
                  :valor="data.item.var91d"
                ></variation>
              </template>

              <template #cell(totalvar)="data">
                <variation
                  :key="data.item.key"
                  :valor="data.item.totalvar"
                ></variation>
              </template>

              <template #cell(perc_total_var)="data">
                <variation
                  :key="data.item.key"
                  :valor="data.item.perc_total_var"
                ></variation>
              </template>
            </b-table>
            <b-pagination
              pills
              v-model="currentPageSpecific"
              :total-rows="rowsActive"
              :per-page="perPageSpecific"
              aria-controls="my-table"
            ></b-pagination>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
const moment = require("moment");
const data_handler = require("../helpers/data_handler");

const rfdc = require("rfdc")();

const dth = require("./../helpers/data_handler");

const vcr = require("./../helpers/varculator");

export default {
  data() {
    return {
      frequency: "12",
      freqs_types: [
        { text: "Monthly", value: 12 },
        { text: "Yearly", value: 360 },
      ],
      items: [],
      total_data: [],
      total_data_by_week: [],
      total_data_by_month: [],
      total_data_by_year: [],
      tabs_history: [],
      activeTabSpecific: null,
      labelSelectTabs: "Days",
      active_tabs_history: [],
      perPageTotal: 10,
      perPageSpecific: 10,
      specificRows: 30,
      optionsSpecificRows: [30, 90, 360, 3650],
      currentPage: 1,
      currentPageSpecific: 1,
      sortDesc: true,
      exterior_fields: [
        {
          key: "entry_date",
          formatter: this.yyyymmddToddmmyyyy,
          label: "Data",
          sortable: true,
        },
        {
          key: "no_currency",
          label: "Moeda",
          sortable: true,
        },
        {
          key: "currency_price",
          label: "Cotação",
          sortable: true,
        },
        {
          key: "nu_invested",
          label: "Aplicado",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
        },
        {
          key: "current_balance",
          label: "Saldo",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
        },

        {
          key: "vl_contribution",
          label: "Aporte",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
        },
        {
          key: "valvar",
          label: "-AP",
          sortable: true,
          formatter: (value) => {
            return +parseFloat(value).toFixed(2);
          },
          sortByFormatted: true,
        },
        {
          key: "perc_var",
          label: "-AP%",
          sortable: true,
        },
        {
          key: "totalvar",
          label: "Var Total",
          sortable: true,
          formatter: (value) => {
            return vcr.localeNumToFloat(value);
          },
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: "perc_total_var",
          label: "%Var Total",
          sortable: true,
        },

        {
          key: "saldobrl",
          label: "Saldo R$",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
        },
      ],
      total_fields: [
        {
          key: "entry_date",
          formatter: this.yyyymmddToddmmyyyy,
          label: "Data",
          sortable: true,
        },
        {
          key: "nu_invested_brl",
          label: "Aplicado R$",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
        },

        {
          key: "nu_invested",
          label: "Aplicado Total R$",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
        },

        {
          key: "current_balance",
          label: "Saldo Total R$",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
        },
        {
          key: "vl_contribution",
          label: "Aporte R$",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
        },
        {
          key: "valvar",
          label: "-AP",
          sortable: true,
        },
        {
          key: "perc_var",
          label: "-AP%",
          sortable: true,
        },
        {
          key: "valvar_ap",
          label: "Var+AP",
          sortable: true,
        },
        {
          key: "perc_var_ap",
          label: "%Var+AP",
          sortable: true,
        },
        {
          key: "totalvar",
          label: "Var Total",
          sortable: true,
          formatter: (value) => {
            return vcr.localeNumToFloat(value);
          },
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: "perc_total_var",
          label: "%Var Total",
          sortable: true,
        },
        {
          key: "nu_invested_usd",
          label: "Aplicado $",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
          variant: "info",
        },

        {
          key: "current_balance_usd",
          label: "Saldo $",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
          variant: "info",
        },
        {
          key: "currency_price",
          label: "Cotação",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
          variant: "info",
        },
      ],
      series: [
        {
          name: "Total",
          data: [],
        },
      ],
      specificSeries: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Evolução",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
      },
      specificChartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Evolução",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  mounted() {
    this.getTotalData();

    this.getTypes();
  },
  methods: {
    setVarDays(arrayName, days) {
      for (let x = this[arrayName].length - 1; x >= 0; x--) {
        let dt = this[arrayName][x].entry_date;

        let elanterior = this[arrayName].find(
          (el) =>
            moment(el.entry_date).format("YYYYMMDD") ==
            moment(dt).add(-days, "days").format("YYYYMMDD")
        );

        let dif = "0";
        let pdif = "0";

        if (elanterior) {
          dif = (
            this[arrayName][x].current_balance - elanterior.current_balance
          ).toFixed(2);

          pdif = vcr
            .calcPercVar(pdif, elanterior.current_balance)
            .toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
            });
        }

        this.$set(this[arrayName][x], `var${days}d`, dif);

        this.$set(this[arrayName][x], `perc_var${days}d`, pdif);
      }
    },
    setTotalVar(arrayName) {
      for (let x = this[arrayName].length - 1; x >= 0; x--) {
        if (x > 0) {
          this.$set(
            this[arrayName][x],
            "valvar",
            (
              parseFloat(this[arrayName][x].current_balance) -
              parseFloat(this[arrayName][x].vl_contribution) -
              parseFloat(this[arrayName][x - 1].current_balance)
            ).toFixed(2)
          );

          this.$set(
            this[arrayName][x],
            "valvar_ap",
            (
              parseFloat(this[arrayName][x].current_balance) -
              parseFloat(this[arrayName][x - 1].current_balance)
            ).toFixed(2)
          );

          this.$set(
            this[arrayName][x],
            "perc_var",
            vcr
              .calcPercVar(
                this[arrayName][x].valvar,
                this[arrayName][x - 1].current_balance
              )
              .toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
              })
          );

          this.$set(
            this[arrayName][x],
            "perc_var_ap",
            vcr
              .calcPercVar(
                this[arrayName][x].valvar_ap,
                this[arrayName][x - 1].current_balance
              )
              .toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
              })
          );
        }

        if (x == 0) {
          this.$set(
            this[arrayName][x],
            "valvar",
            (0).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
            })
          );

          this.$set(
            this[arrayName][x],
            "perc_var",
            "0".toLocaleString("pt-BR", { minimumFractionDigits: 2 })
          );

          this.$set(
            this[arrayName][x],
            "valvar_ap",
            (0).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
            })
          );

          this.$set(
            this[arrayName][x],
            "perc_var_ap",
            "0".toLocaleString("pt-BR", { minimumFractionDigits: 2 })
          );
        }

        this.$set(
          this[arrayName][x],
          "totalvar",
          (
            parseFloat(this[arrayName][x].current_balance) -
            parseFloat(this[arrayName][x].nu_invested)
          ).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
          })
        );

        this.$set(
          this[arrayName][x],
          "perc_total_var",
          vcr
            .calcPercVar(
              this[arrayName][x].totalvar,
              this[arrayName][x].nu_invested
            )
            .toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
            })
        );
      }
    },
    setSaldoBRL(arrayName) {
      for (let x = this[arrayName].length - 1; x >= 0; x--) {
        this.$set(
          this[arrayName][x],
          "saldobrl",
          (
            parseFloat(this[arrayName][x].current_balance) *
            parseFloat(this[arrayName][x].currency_price)
          ).toFixed(2)
        );
      }
    },
    async getTotalData() {
      // this.$nextTick(async function () {

      const f = async () => {
        await this.axios.get("api/total-history").then((response) => {
          this.total_data = response.data;

          this.total_data.sort((a, b) => {
            return a.entry_date > b.entry_date ? 1 : -1;
          });

          this.total_data.forEach((el) => {
            el.entry_date = data_handler.timezoneConversion(el.entry_date);
          });

          this.setTotalVar("total_data");
        });
      };

      await f();

      this.monthlyTotal();

      this.computeYearlyTotal();

      this.updateChartTotal();
    },
    async getTypes() {
      await this.axios.get("api/types").then((response) => {
        this.items = response.data;
        this.getTabHistory(0);
      });
    },
    async getTabHistory(id) {
      let pid = id.toString().replace("ts_", "");

      if (pid == 0) {
        if (this.items.length == 0) {
          return;
        }
        pid = this.items[0].nu_type;
      }

      await this.axios
        .get(`api/group-history/${this.specificRows}/${pid}/`)
        .then((response) => {
          this.active_tabs_history = response.data;

          this.active_tabs_history.sort((a, b) => {
            return a.entry_date > b.entry_date ? 1 : -1;
          });

          for (let x = this.active_tabs_history.length - 1; x > 0; x--) {
            if (this.active_tabs_history[x].vl_contribution == null) {
              this.active_tabs_history[x].vl_contribution = 0;
            }

            this.$set(
              this.active_tabs_history[x],
              "valvar",
              (
                parseFloat(this.active_tabs_history[x].current_balance) -
                parseFloat(this.active_tabs_history[x].vl_contribution) -
                parseFloat(this.active_tabs_history[x - 1].current_balance)
              ).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
              })
            );

            this.$set(
              this.active_tabs_history[x],
              "perc_var",
              vcr
                .calcPercVar(
                  this.active_tabs_history[x].valvar,
                  this.active_tabs_history[x - 1].current_balance
                )
                .toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                })
            );
          }
          this.setSaldoBRL("active_tabs_history");
          this.setTotalVar("active_tabs_history");
          this.setVarDays("active_tabs_history", 7);
          this.setVarDays("active_tabs_history", 28);
          this.setVarDays("active_tabs_history", 91);
          this.getTotalSeries("active_tabs_history");
        });
    },
    yyyymmddToddmmyyyy(dt) {
      return dth.yyyymmddToddmmyyyy(dt);
    },

    updateChartTotal() {
      this.getTotalSeries(this.totalActiveName);
    },
    getTotalSeries(arrayName) {
      try {
        let valores = Array.from(this[arrayName]).map((el) => {
          return {
            entry_date: el.entry_date,
            current_balance: el.current_balance,
            nu_invested: el.nu_invested,
          };
        });

        let tempdata = {
          name: "Total",
          data: valores.map((el) => parseFloat(el.current_balance)),
        };

        let tempaplicado = {
          name: "Invested",
          data: valores.map((el) => parseFloat(el.nu_invested)),
        };

        let tempseries = [tempdata, tempaplicado];

        let tempCategories = {
          xaxis: {
            categories: valores.map((el) =>
              moment(el.entry_date).format("DD-MM-YY")
            ),
          },
        };

        switch (arrayName) {
          case "total_data":
          case "total_data_by_week":
          case "total_data_by_month":
          case "total_data_by_year":
            this.series = tempseries;
            this.chartOptions = tempCategories;
            break;
          case "active_tabs_history":
            this.specificSeries = tempseries;
            this.specificChartOptions = tempCategories;
            break;
        }
      } catch (err) {
        console.log(err);
        return [1, 2, 3];
      }
    },

    calcContributions(arrayName, days) {
      for (let x = 0; x < this[arrayName].length; x++) {
        let el = this[arrayName][x];

        let dte = el.entry_date;
        let tempe = this.total_data.filter(
          (el) =>
            moment(el.entry_date) >= moment(dte).subtract(days, "days") &&
            el.entry_date < dte
        );

        let contribution = parseFloat(el.vl_contribution);

        tempe.forEach((elt) => {
          contribution = contribution + parseFloat(elt.vl_contribution);
        });

        this.$set(this[arrayName][x], "vl_contribution", contribution);
      }
    },
    calcContributionsMonth(arrayName) {
      for (let x = 0; x < this[arrayName].length; x++) {
        let el = this[arrayName][x];

        let dte = el.entry_date;
        let tempe = this.total_data.filter(
          (el) =>
            moment(el.entry_date).format("YYYYMM") ==
              moment(dte).format("YYYYMM") && el.entry_date < dte
        );

        let contribution = parseFloat(el.vl_contribution);

        tempe.forEach((elt) => {
          contribution = contribution + parseFloat(elt.vl_contribution);
        });

        this.$set(this[arrayName][x], "vl_contribution", contribution);
      }
    },

    calcContributionsYear(arrayName) {
      for (let x = 0; x < this[arrayName].length; x++) {
        let el = this[arrayName][x];

        let dte = el.entry_date;
        let tempe = this.total_data.filter(
          (el) =>
            moment(el.entry_date).format("YYYY") ==
              moment(dte).format("YYYY") && el.entry_date < dte
        );

        let contribution = parseFloat(el.vl_contribution);

        tempe.forEach((elt) => {
          contribution = contribution + parseFloat(elt.vl_contribution);
        });

        this.$set(this[arrayName][x], "vl_contribution", contribution);
      }
    },

    weeklyTotal() {
      try {
        this.total_data_by_week = rfdc(
          this.total_data.filter((el) => moment(el.entry_date).weekday() == 1)
        );

        this.calcContributions("total_data_by_week", 6);

        this.setTotalVar("total_data_by_week");
        this.setVarDays("total_data_by_week", 7);
        this.setVarDays("total_data_by_week", 28);
        this.setVarDays("total_data_by_week", 91);
      } catch (err) {
        console.log(err);
      }
    },

    monthlyTotal() {
      try {
        let meses = [];
        let ameses = [];

        //identfy months on array
        this.total_data.forEach((element) => {
          let m = moment(element.entry_date).format("YYYYMM");

          if (!meses.includes(m)) {
            meses.push(m);
          }
        });

        //search max dates by month array
        meses.forEach((m) => {
          let completetempmonth_data = this.total_data.filter(
            (el) => moment(el.entry_date).format("YYYYMM") == m
          );

          let tempmonthdata = completetempmonth_data.map(
            (el) => new Date(el.entry_date)
          );

          let maxmonthdata = Math.max(...tempmonthdata);

          ameses.push(
            rfdc(
              this.total_data.find(
                (el) =>
                  moment(el.entry_date).format("YYYYMMDD") ==
                  moment(maxmonthdata).format("YYYYMMDD")
              )
            )
          );
        });

        this.total_data_by_month = [...ameses];

        this.calcContributionsMonth("total_data_by_month");

        this.setTotalVar("total_data_by_month");
        this.setVarDays("total_data_by_month", 7);
        this.setVarDays("total_data_by_month", 28);
        this.setVarDays("total_data_by_month", 91);
      } catch (err) {
        console.log(err);
      }
      return "";
    },
    computeYearlyTotal() {
      try {
        let years = [];
        let ayears = [];

        //identfy months on array
        this.total_data.forEach((element) => {
          let m = moment(element.entry_date).format("YYYY");

          if (!years.includes(m)) {
            years.push(m);
          }
        });

        //search max dates by month array
        years.forEach((m) => {
          let completetempyear_data = this.total_data.filter(
            (el) => moment(el.entry_date).format("YYYY") == m
          );

          let tempYeardata = completetempyear_data.map(
            (el) => new Date(el.entry_date)
          );

          let maxYeardata = Math.max(...tempYeardata);

          ayears.push(
            rfdc(
              this.total_data.find(
                (el) =>
                  moment(el.entry_date).format("YYYYMMDD") ==
                  moment(maxYeardata).format("YYYYMMDD")
              )
            )
          );
        });

        this.total_data_by_year = [...ayears];

        this.calcContributionsYear("total_data_by_year");

        this.setTotalVar("total_data_by_year");
        this.setVarDays("total_data_by_year", 7);
        this.setVarDays("total_data_by_year", 28);
        this.setVarDays("total_data_by_year", 91);
      } catch (err) {
        console.log(err);
      }
      return "";
    },
  },
  computed: {
    hasChartData() {
      return this.items != null;
    },
    rows() {
      return this.total_data.length;
    },
    rowsActive() {
      return this.active_tabs_history.length;
    },
    totalActive() {
      if (this.frequency == 0) {
        return this.total_data_by_week;
      }

      if (this.frequency == 12) {
        return this.total_data_by_month;
      }

      if (this.frequency == 360) {
        return this.total_data_by_year;
      }

      return [];
    },

    totalActiveName() {
      if (this.frequency == 0) {
        return "total_data_by_week";
      }

      if (this.frequency == 12) {
        return "total_data_by_month";
      }

      if (this.frequency == 360) {
        return "total_data_by_year";
      }

      return [];
    },
  },
};
</script>
<style scoped>
.evo-table,
.history-table {
  font-size: 13px;
}
</style>
