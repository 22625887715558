<template>
  <span
    v-bind:class="{
      'text-success': parseFloat(vlr) > 0.00,
      'text-danger': parseFloat(vlr) < 0.00,
    }"
    class="font-weight-bold"
  >
    {{ vlr.toLocaleString("pt-BR", { minimumFractionDigits: 2 }) }}</span
  >
</template>

<script>
export default {
  props: { valor: { type: String } },
  computed:{
      vlr(){
          if(this.valor == undefined){
            return "0"
          }
          return this.valor.replace(",",".")
      }
  }
};
</script>
