<template>
  <b-nav>
    <b-nav-item> <router-link to="/">Home</router-link> | </b-nav-item>
    <b-nav-item>
      <router-link to="/add-inv">Cadastrar</router-link>
    </b-nav-item>
    <b-nav-item @click="signout" class="g_id_signout">Sign Out</b-nav-item>
  </b-nav>
</template>
<script>

export default {
  methods:{
    signout(){
      window.google.accounts.id.disableAutoSelect();
      this.$router.push("/login");
    }
  }
}
</script>