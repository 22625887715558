<template>
  <b-container fluid id="app">
    <b-row class="fullapp">
      <b-col>
      <h1>FINSELF</h1>
      <router-view />
    </b-col>
    </b-row>
    <footer class="footer-version">
      <span > v{{ ver }} </span>
    </footer>
  </b-container>
</template>

<script>
import { version } from "./../../package";

export default {
  name: "App",
  components: {},
  data() {
    return {
      ver: version || 0,
    };
  },
  created() {
    document.title = "Finself";
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  min-height: 90% !important;
}
.sr-only {
  display: none;
}
.footer-version {
  position: relative;
  margin-left: 95%;
  bottom:0;
  font-size: 0.7em;
}
.fullapp{
  min-height: 100% !important;
}
</style>
