<template>
  <div>
    <b-modal id="modal-1" :title="modalTitle" @shown="clearMessages">
      <b-row>
        <b-col>
          <b-form @submit="onSubmit">
            <b-input-group prepend="" class="mb-2 mr-sm-2 mt-1">
              <b-form-input
                disabled
                id="inline-form-input-dt-appl"
                v-model="invID"
              ></b-form-input>
            </b-input-group>

            <b-input-group prepend="" class="mb-2 mr-sm-2 mt-1">
              <b-form-datepicker
                id="inline-form-input-dt-appl"
                placeholder="Data Registro"
                v-model="form.dt_entry"
              ></b-form-datepicker>
            </b-input-group>

            <b-input-group prepend="" class="mb-2 mr-sm-2 mt-1">
              <b-form-input
                id="inline-form-input-dt-appl"
                placeholder="Valor"
                v-model="form.vlr"
              ></b-form-input>
            </b-input-group>

            <b-input-group prepend="" class="mb-2 mr-sm-2 mt-1">
              <b-form-input
                id="inline-form-input-dt-appl"
                placeholder="Aporte"
                v-model="form.contribution"
              ></b-form-input>
            </b-input-group>

            <b-button type="submit" variant="primary" class="me-2"
              >Submit</b-button
            >
            <b-button type="reset" variant="danger">Reset</b-button>

            <h2 v-show="show_msg_ok">{{ msg_ok }}</h2>
            <h2 v-show="show_msg_error">{{ msg_error }}</h2>
          </b-form>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100"></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        dt_entry: new Date(),
        vlr: "",
        contribution: null,
        id_inv: null,
        nu_name: ""
      },
      msg_ok: "",
      msg_error: "",
      show_msg_ok: false,
      show_msg_error: false,
    };
  },
  mounted() {
    if (this.row != null) {
      this.form.id_inv = this.invID;
      this.form.nu_name = this.row.item.nu_name;
    }
  },

  computed: {
    invID() {
      return this.row.item.id;
    },
    modalTitle(){
      return 'Novo Registro - ' + this.row.item.nu_name
    }
  },
  methods: {
    clearMessages() {
      this.msg_ok = "";
      this.msg_error = "";
      this.show_msg_ok = false;
      this.show_msg_error = false;
    },
    onSubmit(event) {
      this.show_msg_ok = false;
      this.show_msg_error = false;

      event.preventDefault();

      this.form.id_inv = this.invID;

      this.axios.post("/api/entry", this.form).then((response) => {
        if (response.data.msg) {
          this.show_msg_ok = true;
          this.msg_ok = response.data.msg;

          let newdata = JSON.parse(JSON.stringify(this.form));
          newdata.nu_current_balance = newdata.vlr;
          
          if(newdata.dt_entry > this.row.item.max_date){
            // eslint-disable-next-line
              this.row.item.max_date = newdata.dt_entry;
              // eslint-disable-next-line
              this.row.item.nu_current_balance = newdata.nu_current_balance;
           }

          this.onReset();

          
          return;
        }

        if (response.data.err) {
          this.msg_error = response.data.err;
          this.show_msg_error = true;
          return;
        }
      });
    },
    onReset() {
      this.form.dt_entry = new Date();
      this.form.vlr = "";
      this.form.contribution = null;
    },
  },
};
</script>
