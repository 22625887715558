<template>
  <b-row>
    <b-col>
      <FinNavBar></FinNavBar>
    </b-col>
    <b-col cols="12">
      <h4>Novo Investimento</h4>
    </b-col>
    <b-col cols="10" offset="1">
      <b-form @submit="onSubmit" @reset="onReset">
        <b-input-group prepend="Tipo" class="mb-2  mt-1">
          <label class="sr-only" for="inline-form-custom-select-pref"></label>
          <b-form-select
            id="inline-form-custom-select-pref"
            class="mb-2  mb-sm-0"
            :options="tipos_investimento"
            v-model="form.type"
            :value="null"
          ></b-form-select>
        </b-input-group>

        <b-input-group prepend="Currency" class="mb-2  mt-1">
          <label class="sr-only" for="inline-form-custom-select-pref"></label>
          <b-form-select
            id="inline-form-custom-select-pref"
            class="mb-2  mb-sm-0"
            :options="currency_list"
            v-model="form.cur"
          ></b-form-select>
        </b-input-group>

        <b-input-group prepend="" class="mb-2 mt-1">
          <b-form-datepicker
            id="inline-form-input-dt-appl"
            placeholder="Data Aplicação"
            v-model="form.dt_appl"
          ></b-form-datepicker>
        </b-input-group>

        <b-input-group prepend="" class="mb-2 mt-1" v-if="!isQuote">
          <b-form-input
            id="inline-form-input-dt-appl"
            placeholder="Valor"
            v-model="form.vlr"
          ></b-form-input>
        </b-input-group>

        <b-input-group prepend="" class="mb-2 mt-1">
          <b-form-input
            id="inline-form-input-dt-appl"
            placeholder="Name"
            v-model="form.name"
          ></b-form-input>
        </b-input-group>

        <b-input-group prepend="" class="mb-2 mr-sm-2 mt-1" v-if="!isQuote">
          <b-form-datepicker
            id="inline-form-input-dt-appl"
            placeholder="Data Término (opcional)"
            v-model="form.dt_expires"
          ></b-form-datepicker>
        </b-input-group>

        <b-form-group v-if="!isQuote">
          <b-form-checkbox
            id="checkbox-1"
            v-model="form.isLiquid"
            name="checkbox-1"
            value="0"
            checked-value="1"
            unchecked-value="0"
            class="me-1"
          >
            <span class="ms-2">Dá pra resgatar antecipado?</span>
          </b-form-checkbox>
        </b-form-group>

        <b-input-group prepend="" class="mb-2 mr-sm-2 mt-1" v-if="isQuote">
          <b-form-input
            id="inline-form-input-dt-appl"
            placeholder="Preço"
            v-model="form.price"
          ></b-form-input>
        </b-input-group>

        <b-input-group prepend="" class="mb-2 mr-sm-2 mt-1" v-if="isQuote">
          <b-form-input
            id="inline-form-input-dt-appl"
            placeholder="Quantidade"
            v-model="form.amount"
          ></b-form-input>
        </b-input-group>

        <b-button type="submit" variant="primary" class="me-2">Submit</b-button>
        <b-button type="reset" variant="danger">Reset</b-button>

        <h2 v-show="show_msg_ok">{{ msg_ok }}</h2>
        <h2 v-show="show_msg_error">{{ msg_error }}</h2>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import FinNavBar from "../components/FinNavBar.vue";

export default {
  components: { FinNavBar },
  data() {
    return {
      form: {
        dt_appl: "",
        dt_expires: null,
        isLiquid: 0,
        name: "",
        type: "",
        vlr: "",
        price: "",
        amount: "",
        cur: "USD",
      },
      tipos_investimento: ["vazio"],
      currency_list: ["USD", "BRL"],
      msg_ok: "",
      msg_error: "",
      show_msg_ok: false,
      show_msg_error: false,
    };
  },

  mounted: function () {
    this.setSelectTypes();
  },
  computed: {
    isQuote() {
      return this.form.type == 5 || this.form.type == 6;
    },
  },
  methods: {
    onSubmit(event) {
      this.show_msg_ok = false;
      this.show_msg_error = false;

      let urlroute = "/api/investment";

      if (this.form.type == 5 || this.form.type == 6) {
        urlroute = "/api/var-investment";
      }

      event.preventDefault();
      this.axios.post(urlroute, this.form).then((response) => {
        if (response.data.msg) {
          this.show_msg_ok = true;
          this.msg_ok = response.data.msg;
          this.onReset();
          return;
        }

        if (response.data.err) {
          this.msg_error = response.data.err;
          this.show_msg_error = true;
          return;
        }
      });
    },
    onReset() {
      this.form.id = null;
      this.form.dt_appl = "";
      this.form.dt_expires = "";
      this.form.isLiquid = 0;
      this.form.name = "";
      this.form.vlr = "";
      this.form.price = "";
      this.form.amount = "";
    },
    setSelectTypes() {
      try {
        this.axios.get("/api/investment-types").then((response) => {
          let x = response.data.map((el) => {
            return { value: el.id, text: el.no_name };
          });
          this.tipos_investimento = x;
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
