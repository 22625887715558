exports.calcPercVar = (valvar, nu_invested) => {
  if (nu_invested == 0) {
    return this.localeNumToFloat(valvar);
  }

  return (this.localeNumToFloat(valvar) / this.localeNumToFloat(nu_invested)) * 100;
};

exports.calcPercVarString = (valvar, nu_invested) => {
  if (nu_invested == 0) {
    return this.localeNumToFloat(valvar).toLocaleString("pt-BR", {
      maximumFractionDigits: 2,
    });
  }

  return ((this.localeNumToFloat(valvar) / this.localeNumToFloat(nu_invested)) * 100).toLocaleString("pt-BR", {
      maximumFractionDigits: 2,
    });
};

exports.calcPercVarByDays = (valvar, nu_invested) => {
  if (nu_invested == 0) {
    return this.localeNumToFloat(valvar);
  }

  return (this.localeNumToFloat(valvar) / this.localeNumToFloat(nu_invested)) * 100;
};


exports.localeNumToFloat = (num) => {

  if(num == null){
    return 0;
  }
  
  if (!num.toString().includes(",")) {
    return parseFloat(num);
  }
  return parseFloat(num.toString().replace(".", "").replace(",", "."));
};
