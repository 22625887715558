<template>
  <b-container fluid>
    <FinNavBar></FinNavBar>
    <my-investments />
  </b-container>
</template>

<script>
import FinNavBar from "../components/FinNavBar.vue";
import MyInvestments from "./MyInvestments.vue";

export default {
  name: "Home",
  components: {
    MyInvestments,
    FinNavBar
},
  data() {
    return {
      msg: "debugggggggggg",
      showII: true,
    };
  },
  mounted() {},
};
</script>
