const { formatInTimeZone  } = require('date-fns-tz')

exports.yyyymmddToddmmyyyy = (dt) => {
    if (dt == null) {
      return "";
    }
    let resdt = dt.substr(8, 2) + "/" + dt.substr(5, 2) + "/" + dt.substr(0, 4);
    return resdt;
  }

exports.timezoneConversion = (dt) => {
  return formatInTimeZone (dt, Intl.DateTimeFormat().resolvedOptions().timeZone, 'yyyy-MM-dd HH:mm:ssXXX' );
}