<template>
  <b-row class="mt-4">
   

    <b-col cols="6">
      <h2>Despesas YTD {{ expenses_ytd }}</h2>
      <b-table
        :items="expenses"
        sortBy="entry_date"
        :sort-desc="sortDesc"
        :fields="fieldsExpenses"
      ></b-table>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      dividends: [],
      dividends_ytd: 0,
      expenses_ytd: 0,
      expenses: [],
      sortDesc: true,
      fieldsDiv: [
        {
          key: "entry_date",
          label: "Data",
          sortable: true,
          formatter: "yyyymmddToddmmyyyy"
        },
         {
          key: "value",          
          label: "Valor",
          sortable: true,
        },
         {
          key: "stock_name",          
          label: "Origem",
          sortable: true,
        },
        {
          key: "no_currency",          
          label: "Moeda",
          sortable: true,
        },
        {
          key: "type",          
          label: "Tipo",
          sortable: true,
        },
        {
          key: "total_brl",          
          label: "Total R$",
          sortable: true,
        },
      ],
      fieldsExpenses:[
          {
          key: "entry_date",
          label: "Data",
          sortable: true,
          formatter: "yyyymmddToddmmyyyy"
        },
         {
          key: "value",          
          label: "Valor",
          sortable: true,
        },
         {
          key: "id_investment",          
          label: "Origem",
          sortable: true,
        },
        {
          key: "no_currency",          
          label: "Moeda",
          sortable: true,
        },
        {
          key: "expense_type",          
          label: "Tipo",
          sortable: true,
        }
       

      ]
    };
  },
  mounted() {
    // this.getDividendsYTD();
    this.getExpensesYTD();
    // this.getDividends();
    this.getExpenses();
  },
  methods: {
    async getDividendsYTD() {
      await this.axios.get("api/dividends-ytd").then((response) => {
        this.dividends_ytd = response.data[0].total_dividends;
      });
    },

    async getDividends() {
      await this.axios.get("api/dividends").then((response) => {
        this.dividends = response.data;
      });
    },

    async getExpensesYTD() {
      await this.axios.get("api/expenses-ytd").then((response) => {
        this.expenses_ytd = response.data[0].total_expenses;
      });
    },
    async getExpenses() {
      await this.axios.get("api/expenses").then((response) => {
        this.expenses = response.data;
      });
    },
    yyyymmddToddmmyyyy(dt) {
      const dth = require("./../helpers/data_handler");
      return dth.yyyymmddToddmmyyyy(dt);
    },
  },
};
</script>
