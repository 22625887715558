import  VueRouter from 'vue-router'

import IncludeInvesment from './../views/IncludeInvestment.vue'
import Login from './../views/Login.vue'

import Home from './../views/Home'

const routes = [
    {
        path: '/add-inv',
        component: IncludeInvesment
    },    
    {
        path: '/login', 
        component: Login
    },
    {
        path: '/', 
        component: Home
    }
]

const router = new VueRouter({
    routes // short for `routes: routes`
    ,mode: 'history'
  })

  export default router