<template>
  <b-row>
    <b-col cols="6">
      <apexchart
        :options="chartOptionsCategoria"
        :series="series"
        :key="categoryData[0]"
        height="400px"
        width="100%"
      ></apexchart>
    </b-col>
    <b-col cols="6">
      <apexchart
        :options="chartOptionsTipo"
        :series="seriesTipo"
        :key="categoryData[1]"
        height="400px"
        width="100%"
      ></apexchart>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    groupedData: {
      type: Array,
    },
    isgrouped: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      series: this.categoryData(),

      chartOptionsCategoria: {
        chart: {
          type: "pie",
          height: "50%",
        },
        labels: ["RF", "RV", "RE"],
        colors: ["#FF8C00", "#9400D3", "#005d5d"],
        responsive: this.getDefaultResponsive(),
        title: {
          text: "Distribuição por Categoria",
        },
      },
      seriesTipo: this.tipoData(),
      chartOptionsTipo: {
        chart: {
          type: "polarArea",
          height: "50%",
        },
        labels: this.tipoLabel(),
        colors: [
          "#6929c4",
          "#1192e8",
          "#005d5d",
          "#9f1853",
          "#fa4d56",
          "#570408",
        ],
        responsive: this.getDefaultResponsive(),
        title: {
          text: "Distribuição por Tipo",
        },
      },
    };
  },
  mounted() {
    this.series = this.categoryData();
  },
  methods: {
    getDefaultResponsive() {
      return [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ];
    },
    categoryData() {
      if (this.groupedData == null) {
        return [1, 0];
      }

      let rfsum = 0;
      let rvsum = 0;
      let resum = 0;

      const reducer = (previousValue, currentValue) => {
        if (currentValue.no_currency == "USD" && !this.isgrouped) {
          return parseFloat(
            (
              previousValue +
              parseFloat(
                currentValue.nu_current_balance.replace(",", ".") *
                  currentValue.currency_price.replace(",", ".")
              )
            ).toFixed(2)
          );
        }

        return parseFloat(
          (
            previousValue +
            parseFloat(currentValue.nu_current_balance.replace(",", "."))
          ).toFixed(2)
        );
      };

      let rfs = this.groupedData
        .filter((el) => el.investment_category == "RF")
        .reduce(reducer, rfsum);

      let rvs = this.groupedData
        .filter((el) => el.investment_category == "RV")
        .reduce(reducer, rvsum);

      let res = this.groupedData
        .filter((el) => el.investment_category == "RE")
        .reduce(reducer, resum);

      return [rfs, rvs, res];
    },
    tipoData() {
      return this.groupedData.map(
        (el) => +parseFloat(el.nu_current_balance *
                  el.currency_price.replace(",", ".")).toFixed(2)
      );
    },
    tipoLabel() {
      if (this.isgrouped) {
        return this.groupedData.map((el) => el.no_type);
      }

      //alert("oi");
      return this.groupedData.map((el) => el.nu_name);
    },
  },
};
</script>
