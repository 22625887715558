<template>
  <div class="flex-container mt-5">
    <span>
      <div id="googleLoginBtn" ref="googleLoginBtn" />
    </span>
  </div>
</template>

<script>
// Reference:: https://medium.com/@jebasuthan/signup-with-google-using-vuejs-11c9d4428250
// https://adnan-tech.com/google-sign-in-button-javascript-php/

export default {
  data() {
    return {
      googleSigninClicked: false,
    };
  },
  mounted() {
    this.googleInit();
  },
  methods: {
    onSignInSuccess(googleUser) {
      console.log(googleUser);
      // `googleUser` is the GoogleUser object that represents the just-signed-in user.
      // See https://developers.google.com/identity/sign-in/web/reference#users

      let id_token = googleUser.credential;

      this.axios.post("api/login", { id_token }).then(() => {
        this.$router.push("/");
      });
    },
    onSignInError(error) {
      // `error` contains any error occurred.
      console.log("OH NOES", error);
    },

    googleInit() {
      const gClientId =
        "101217884836-qgsi028mka3s5cuqlsqcgohdn24duslh.apps.googleusercontent.com";

      window.google.accounts.id.initialize({
        client_id: gClientId,
        callback: this.onSignInSuccess,
        auto_select: true,
      });
      window.google.accounts.id.renderButton(this.$refs.googleLoginBtn, {
        text: "signin",
        size: "large",
        shape: 'square',
        theme: "outline", // option : filled_black | outline | filled_blue
      });
    },
  },
};
</script>

<style>
.g-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #3c82f7;
  color: #fff;
  box-shadow: 0 3px 0 #0f69ff;
}

.g-signin-button:hover {
  cursor: pointer;
}

.flex-container {
  display: flex;
  justify-content: center;
}
</style>
